<template>
  <b-card>
    <b-card-text>
      <b-row class="w-75 mx-auto">
        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
          xs="12"
        >
          <b-form-group
            label-size="lg"
            label="Usuario"
            label-for="username"
          >
            <validation-provider
              #default="{ errors }"
              name="Usuário"
              vid="username"
              rules="required"
            >
              <b-form-input
                id="username"
                v-model="formData.username"
                name="username"
                :state="errors.length > 0 ? false : null"
                placeholder="Nome de Usuário"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
          xs="12"
        >
          <b-form-group
            label-size="lg"
            label="Senha"
            label-for="password"
          >
            <validation-provider
              #default="{ errors }"
              name="Senha"
              vid="password"
              rules="required"
            >
              <b-form-input
                id="password"
                v-model="formData.password"
                name="password"
                :state="errors.length > 0 ? false : null"
                placeholder="********"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
          xs="12"
        >
          <b-form-group
            label-size="lg"
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="formData.email"
              trim
              placeholder="exemplo@gmail.com"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
          xs="12"
        >
          <b-form-group
            label-size="lg"
            label="Senha do Email"
            label-for="email_password"
          >
            <b-form-input
              id="email_password"
              v-model="formData.email_password"
              trim
              placeholder="Senha do Email"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="mt-2"
        >
          <h4>Número:</h4>
          <hr style="margin-top: -5px;">
        </b-col>

        <b-col
          cols="12"
        >
          <b-input-group>
            <b-input-group-prepend is-text>
              <span>
                BR (+55)
              </span>
            </b-input-group-prepend>
            <b-form-input
              id="phone"
              v-model="formData.phone"
              v-mask="'(##) #####-####'"
              placeholder="(11) 95555-5555"
            />
          </b-input-group>
        </b-col>

        <b-col
          cols="12"
          class="mt-3"
        >
          <h4>Detalhes:</h4>
          <hr style="margin-top: -5px;">
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label-size="lg"
            label="Rede Social"
            label-for="social_network"
          >
            <b-form-select
              id="social_network"
              v-model="formData.social_network"
              :state="formData.social_network === null ? false : true"
              :options="social_network_options"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione uma rede social!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
          xs="12"
        >
          <b-form-group
            label-size="lg"
            label="Local"
            label-for="account_local"
          >
            <b-form-input
              id="name"
              v-model="formData.account_local"
              trim
              placeholder="Local onde está a conta."
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
          xs="12"
        >
          <b-form-group
            label-size="lg"
            label="Verificações"
            label-for="sms_verification"
          >
            <b-form-input
              id="name"
              v-model="formData.sms_verification"
              trim
              type="number"
              placeholder="Verificações por SMS."
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
          xs="12"
        >
          <b-form-group
            label-size="lg"
            label="Qualidade da Conta"
            label-for="account_quality"
          >
            <b-form-select
              id="account_quality"
              v-model="formData.account_quality"
              :state="formData.account_quality === null ? false : true"
              :options="account_quality_options"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione uma qualidade!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
          xs="12"
        >
          <b-form-group
            label-size="lg"
            label="Pontuação da conta"
            label-for="account_quality_pontuation"
          >
            <b-form-input
              id="account_quality_pontuation"
              v-model="formData.account_quality_pontuation"
              trim
              type="number"
              placeholder="Pontuação da conta"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
          xs="12"
        >
          <b-form-group
            label-size="lg"
            label="Situação da Conta"
            label-for="status"
          >
            <b-form-select
              id="status"
              v-model="formData.status"
              :state="formData.status === null ? false : true"
              :options="status_options"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione uma situação!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
          xs="12"
        >
          <b-form-group
            label-size="lg"
            label="Plataforma Atual"
            label-for="platform"
          >
            <b-form-select
              id="platform"
              v-model="formData.platform"
              :state="formData.platform === null ? false : true"
              :options="platform_options"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                >Por favor, selecione uma plataforma!</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
          xs="12"
        >
          <b-form-group
            label-size="lg"
            label="Bloqueada Até: (Se houver)"
            label-for="status"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="Trash2Icon"
                  class="feather-icon cursor-pointer"
                  size="18"
                  @click.stop="formData.blocked_at = null"
                />
              </b-input-group-prepend>
              <DateTimePicker
                v-model="formData.blocked_at"
                :render="renderDatetimePicker"
                :config="config"
                locale="pt"
                alt-timezone="America/Sao_Paulo"
                date-timezone="UTC"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="6"
          xs="12"
        >
          <b-form-group
            label-size="lg"
            label="Plataformas atualmente cadastradas"
            label-for="status"
          >
            <v-select
              v-model="formData.platform_register"
              multiple
              label="title"
              :options="platform_register_options"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="mt-2"
        >
          <b-form-group
            label-size="lg"
            label="Comentários/Observações:"
            label-for="status"
          >
            <b-form-textarea
              id="textarea-default"
              v-model="formData.comments"
              placeholder="Comentários da sua conta e observações."
              rows="5"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          class="d-flex justify-content-center"
        >
          <b-button
            :disabled="loading"
            variant="relief-success"
            @click.stop="addAccount()"
          >
            <span v-if="!loading">Salvar</span>
            <b-spinner
              v-else
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BSpinner, BCard, BCardTitle, BCardText, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BButton, BInputGroupPrepend, BFormSelect, BFormSelectOption, BFormTextarea
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import IndexMixin from './IndexMixin'
import DateTimePicker from '@/components/DateTimePicker.vue'
import checkSubscription from '../checkSubscription'

export default {
  components: {
    BSpinner,
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BInputGroupPrepend,
    BFormSelect,
    BFormSelectOption,
    DateTimePicker,
    vSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    mask
  },
  mixins: [IndexMixin, checkSubscription],
  data: () => ({
    required,
    email
  })
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
