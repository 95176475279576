export default {
  data: () => ({
    loading: false,
    renderDatetimePicker: false,
    formData: {
      username: '',
      password: '',
      email: '',
      email_password: '',
      phone: '',
      account_local: '',
      sms_verification: 0,
      account_quality: null,
      account_quality_pontuation: 0.00,
      status: null,
      platform: null,
      social_network: 'Instagram',
      platform_register: [],
      blocked_at: null,
      comments: ''
    },
    social_network_options: [
      {
        value: 'Instagram',
        text: 'Instagram'
      },
      {
        value: 'Tiktok',
        text: 'Tiktok'
      },
      {
        value: 'Google (GMAIL)',
        text: 'Google (GMAIL)'
      },
      {
        value: 'Youtube',
        text: 'Youtube'
      },
      {
        value: 'Facebook',
        text: 'Facebook'
      },
      {
        value: 'Twitter',
        text: 'Twitter'
      },
      {
        value: 'Outlook',
        text: 'Outlook'
      },
      {
        value: 'Linkedin',
        text: 'Linkedin'
      },
      {
        value: 'Outros',
        text: 'Outros'
      }
    ],
    platform_options: [
      {
        text: 'Siga Social',
        value: 'Siga Social'
      },
      {
        text: 'Dizu',
        value: 'Dizu'
      },
      {
        text: 'GNI',
        value: 'GNI'
      },
      {
        text: 'Kzom',
        value: 'Kzom'
      },
      {
        text: 'Outros',
        value: 'Outros'
      }
    ],
    platform_register_options: [
      {
        title: 'Siga Social'
      },
      {
        title: 'Dizu'
      },
      {
        title: 'GNI'
      },
      {
        title: 'Kzom'
      },
      {
        title: 'Outros'
      }
    ],
    config: {
      enableTime: true,
      altInput: true,
      altFormat: 'd/m/Y H:i',
      dateFormat: 'Y-m-d H:i'
    },
    account_quality_options: [
      {
        text: 'Baixa',
        value: 'Baixa'
      },
      {
        text: 'Média',
        value: 'Média'
      },
      {
        text: 'Alta',
        value: 'Alta'
      },
      {
        text: 'Muito Alta',
        value: 'Muito Alta'
      }
    ],
    status_options: [
      {
        value: 'Ativa',
        text: 'Ativa'
      },
      {
        value: 'Usada recentemente',
        text: 'Usada recentemente'
      },
      {
        value: 'Movimentando',
        text: 'Movimentando'
      },
      {
        value: 'Descansando',
        text: 'Descansando'
      },
      {
        value: 'Bloqueio Temporário',
        text: 'Bloqueio Temporário'
      },
      {
        value: 'Verificação',
        text: 'Verificação'
      },
      {
        value: 'Verificação SMS',
        text: 'Verificação SMS'
      },
      {
        value: 'Verificação Email',
        text: 'Verificação Email'
      },
      {
        value: 'Trocar Senha',
        text: 'Trocar Senha'
      },
      {
        value: 'Banida',
        text: 'Banida'
      },
      {
        value: 'Aposentada',
        text: 'Aposentada'
      },
      {
        value: 'Outros',
        text: 'Outros'
      }
    ]
  }),
  computed: {
    isMobile () {
      return window.innerWidth < 580
    }
  },
  mounted () {
    this.renderDatetimePicker = true
  },
  methods: {
    async addAccount () {
      this.loading = true
      console.log({
        platform_register: JSON.stringify(this.formData.platform_register.map(item => item.title))
      })
      const url = '/user/controle_infinity/accounts/add'
      const params = {
        username: this.formData.username,
        password: this.formData.password,
        email: this.formData.email,
        email_password: this.formData.email_password,
        phone: this.formData.phone,
        account_local: this.formData.account_local,
        sms_verification: this.formData.sms_verification,
        account_quality: this.formData.account_quality,
        account_quality_pontuation: this.formData.account_quality_pontuation,
        status: this.formData.status,
        platform: this.formData.platform,
        social_network: this.formData.social_network,
        platform_register: JSON.stringify(this.formData.platform_register.map(item => item.title)),
        blocked_at: this.formData.blocked_at,
        comments: this.formData.comments
      }

      await this.$http.post(url, params)
        .then(() => {
          this.$swal({
            type: 'success',
            title: 'Conta adicionada com sucesso!',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          })
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            title: 'Erro ao adicionar conta!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-danger'
            }
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
